import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { Modal, Row, Button } from "react-bootstrap";
import ReactSelect from "react-select";

import { SwitchCheckbox } from "../../../../../components/Form";
import GlobalFilter from "../../../../../components/DataTable/GlobalFilter";
import DataTableColumnCheckBox from "../../../../../components/DataTable/DataTableCheckbox";
import PaginationTable from "../../../../../components/DataTable/paginationTable-BuscaExt";
import FilterTable from "../../../../../components/DataTable/filterTable";
import DataNotFound from "../../../../../components/DataNotFound";
import api from "../../../../../services/api";

import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";

import { IListLiberacaoAlumisoft, IListTabelaPrecos } from "../../../../../common/interfaces/liberacoes.interfaces";
import { IPaginacao } from "../../../../../common/interfaces/paginacao.interfaces";
import { ToastErrorContext } from "../../../../../contexts/toast/toastErrorContext";
import { useAuth } from "../../../../../hooks/auth";
import { toast } from "react-toastify";

interface IPrecos {
  dados: IListLiberacaoAlumisoft
}

const TabPrecos: React.FC<IPrecos> = (props) => {

  //Fields Typed
  const DataTableTS: any = FilterTable;
  const DataTableTSModal: any = PaginationTable;

  const { user } = useAuth();

  const queryString = require('querystring');

  // Context
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);
  const sweetAlertContext = useContext(SweetAlertContext);

  //States
  const [show, setShow] = useState(false);
  const [tableData, setTableData] = useState<IListTabelaPrecos[]>([]);
  const [tableDataModal, setTableDataModal] = useState<IListTabelaPrecos[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [referencia, setReferencia] = useState<any>(null);
  const [filter, setFilter] = useState('');
  const [filterReferencia, setFilterReferencia] = useState<number | undefined>();
  const [pageCount, setPageCount] = useState(0);
  const [tableSelectedRows, setTableSelectedRows] = useState<number[]>([]);
  const [reloadDataTable, setReloadDataTable] = useState(false);
  const [filterHomologador, setFilterHomologador] = useState<boolean>(false);

  useEffect(() => {
    setTableLoading(true);
    setTableSelectedRows([]);
    Promise.all([
      api.get(`geral/SelecaoEmpresasComTabelasDePreco/${filterHomologador}`),
      api.get(`serial/ListaSerialTabelaPrecoLiberacao/${props.dados.idSerial}/${filterHomologador}`),
      api.get(`serial/ListaTabelaPrecoLiberacao/${filterHomologador}`)
    ])
    .then((promise: any) => {
      setReferencia(promise.at(0).data);
      setTableData(promise.at(1).data);
      setTableDataModal(promise.at(2).data);
      setPageCount(promise.at(2).data.length);
    })
    .catch((err) => toastError.notification(err.message))
    .finally(() => setTableLoading(false));

  }, [filterHomologador, reloadDataTable])
  

  //Callbacks
  const fetchData = useCallback(async (paginacao: IPaginacao) => {
    const { data: { dados, paginas }} = await api.get(`serial/listaTabelaPrecoDisponivel?idSerial=${props.dados.idSerial}&${queryString.stringify(paginacao)}`)
    setPageCount(paginas);
    setTableDataModal(dados);
  }, []);

  //Handles  
  const handleClose = () => {
    setShow(false);
    setFilterReferencia(undefined);
    setFilter('');
  }
  const handleShow = () => setShow(true);

  const handleFiltroReferencia = (filtro?: number) => {
    setFilterReferencia(filtro);
  };

  const handleSubmit = async () => {
    if (tableSelectedRows.length != 0) {
      const dados = { ItemsSelecionados: tableSelectedRows, idSerial: props.dados.idSerial, homologador: filterHomologador };

      try {
        await api.post('serial/salvarTabelaPreco', dados);
        toast.success("A ação foi concluída.");
        setReloadDataTable(reload => !reload);
        setShow(false);
      } catch (err) {
        toast.error(getSingleErrorMessage(err));
      }
    } else {
      toastContext.notification("Selecione ao menos uma tabela de preço.", "warn");
    }
  };

  const handleDelete = ({ id, tabelaPreco, referencia }: IListTabelaPrecos) => {
    const title = `Excluir "${tabelaPreco}"? `;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`serial/removerTabelaPreco/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleFiltroHomologador = (filtro: boolean) => {
    setFilterHomologador(filtro);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Tabela de Preço",
        accessor: "tabelaPreco",
        display: "align-middle",
      },

      {
        Header: "Data",
        accessor: "dataAtualizacao",
        display: "align-middle",
        render: (value: any) => {
          return (<>
            {value.dataAtualizacao ? new Date(value.dataAtualizacao).toLocaleDateString("dd/mm/yyyy") : ""}
          </>)
        }
      },
      {
        Header: "Homologador",
        accessor: "homologador",
        display: "align-middle",
      }
    ],
    []
  );

  if (user?.role.indexOf("ConsultaLiberacao") === -1) {
    const deleteColumn = {
      Header: "Ação",
      accessor: "acao",
      disableSortBy: true,
      display: "align-middle column-table-1b",
      Cell: ({ row: { original } }: { row: { original: IListTabelaPrecos } }) => (
        <Button variant="ghost-dark" className="p-2" onClick={() => handleDelete(original)}>
          <i className="far fa-trash-alt icon m-0"></i>
        </Button>
      ),
    }

    columns.push(deleteColumn);
  }
  const columnsModal = useMemo(
    () => [
      {
        Header: "",
        accessor: "check",
        display: "align-middle text-center column-table-1b",
        Cell: ({ row }: any) => (
          <DataTableColumnCheckBox
            selectProps={row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: "Tabela de Preço",
        accessor: "tabelaPreco",
        display: "align-middle",
      },
      {
        Header: "Data",
        accessor: "dataAtualizacao",
        display: "align-middle",
        render: (value: any) => {
          return (<>
            {value.dataAtualizacao ? new Date(value.dataAtualizacao).toLocaleDateString("dd/mm/yyyy") : ""}
          </>)
        }
      },
    ],
    [filterHomologador]
  );

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row px-3 align-items-center">
            <div className="col">
              <h5 className="mb-2">Razão Social:</h5>
              <p className="mb-0">{props.dados.razaoSocial}</p>
            </div>
            <div className="col">
              <h5 className="mb-2">Nome Fantasia:</h5>
              <p className="mb-0">{props.dados.nomeFantasia}</p>
            </div>
            <div className="col-auto">
              <h5 className="mb-2">Serial:</h5>
              <p className="mb-0">{props.dados.serial}</p>
            </div>
          </div>
        </div>
      </div>
      <Row className="mt-4">
        <div className="col-auto ml-auto">
          {user!.role.indexOf("Liberacao") >= 0 && (
            <Button variant="white" onClick={handleShow}>
              Adicionar
            </Button>
          )}
        </div>
      </Row>
      <Row className="mt-3">
        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' small-box-loader' : '')} >

            {tableData && (
              <DataTableTS
                filter={filter}
                columns={columns}
                data={tableData}
                bordered={false}
                filterOptional2={filterHomologador}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={
                tableLoading == false ? tableData?.length === 0 : false
              }
            ></DataNotFound>
          </div>
        </div>
      </Row>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Tabela de Preços</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col">
              <label className="form-label">Tipo de Liberação</label>
              <SwitchCheckbox
                name="ehHomologador"
                id="ehHomologador"
                label="Homologador?"
                style={{ height: "33px" }}
                onChange={(e: any) => handleFiltroHomologador(e.target.checked)}
              />
            </div>
            <p className="mt-2">Se o botão "homologador" estiver selecionado, os pacotes selecionados abaixo receberão essa marcação.</p>
          </Row>
          <Row className="mt-4">
            <label className="form-label col">Selecione os pacotes:</label>
            <div className="col-3">
              <ReactSelect
                classNamePrefix="react-select"
                name="referencia"
                options={referencia}
                isClearable={true}
                placeholder="Busca por Empresa..."
                onChange={(e: any) => handleFiltroReferencia(e?.value)}
              />
            </div>
            <div className="col-auto">
              <GlobalFilter setGlobalFilter={setFilter} />
            </div>
          </Row>

          <Row className="mt-3">
            <div className={'dimmer' + (tableLoading ? ' active' : '')}>
              <div className="loader"></div>
              <div className={"dimmer-content" + (tableLoading ? ' small-box-loader' : '')} >

                {tableData && (
                  <DataTableTSModal
                    setSelectedRows={setTableSelectedRows}
                    pageCount={pageCount}
                    filter={filter}
                    fetchData={fetchData}
                    filterOptional1={filterReferencia}
                    filterOptional2={filterHomologador}
                    columns={columnsModal}
                    data={tableDataModal}
                    bordered={false}
                    initialState={{
                      sortBy: [
                        {
                          id: "id",
                          desc: false,
                        },
                      ],
                    }}
                  />
                )}
                <DataNotFound
                  visible={
                    tableLoading == false ? tableDataModal?.length === 0 : false
                  }
                ></DataNotFound>
              </div>
            </div>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabPrecos;
