import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Form, Accordion, Card } from "react-bootstrap";
import { Input, Select, InputDecimal } from "../../../../../components/Form";
import {
  situacao,
  status,
  simOuNao,
} from "../../../../../components/Form/Select/options";
import api from "../../../../../services/api";

import { ProdutoContext } from "../../../../../contexts/produtos/produtosContext";

const PerfilTabGeral: React.FC<any> = ({
  selectValues,
  setLogoLinha,
  loadingValues,
  material,
}) => {

  // URL Params
  const { pId }: any = useParams();

  // É um novo componente?
  const novo = pId == 0;

  //Constants
  const perfilComposto = [
    { label: "Não", value: 0 },
    { label: "Sim", value: 3 },
    { label: "Interno", value: 1 },
    { label: "Externo", value: 2 },
  ];

  // Contexts
  const produtoContext = useContext(ProdutoContext);
  // //States
  const [linha, setLinha] = useState<any>([]);
  const [prenativo, setPrenativo] = useState<any>([]);
  const [unidadeValorizacao, setUnidadeValorizacao] = useState([]);
  const [classeIds, setClasseIds] = useState<any>([]);

  const fetchTemperaOptions = async (ligaValue: any) => {
    const temperaOptionsResponse = await api.get(`geral/ObterSelectById/${ligaValue}`);
    selectValues.formRef?.current?.setFieldValue("tempera", temperaOptionsResponse.data);
  };
  //Effects
  useEffect(() => {
    produtoContext.setEntrouEmEdicao(false);
  }, []);

  if (!produtoContext.entrouEmEdicao &&
    selectValues &&
    selectValues.liga?.length > 0 &&
    material &&
    material.liga == null
  ) {
    const liga6060 = selectValues.liga.find((p: any) => p.label == "6060");
    const temperaT5 = selectValues.tempera.find((p: any) => p.label == "T5");
    selectValues.formRef?.current?.setFieldValue("liga", liga6060);
    selectValues.formRef?.current?.setFieldValue("tempera", temperaT5);
  }

  useEffect(() => {
    if (produtoContext.entrouEmEdicao && Object.keys(selectValues).length != 0) {
      selectValues.formRef?.current?.setFieldValue("situacao", situacao[0]);
    }
  }, [produtoContext.entrouEmEdicao]);

  //Handles
  const handleReferenciaLinha = async ({ value }: any) => {
    selectValues.formRef?.current?.clearField("linha");

    const { data } = await api.get(`linha/select/${value}`);

    //buscar as classes ids pela referencia
    await api.get(`geral/SelecaoClassePorReferenciaId/${value}`)
      .then(data => {
        setClasseIds(data.data)
      });
    
    setLinha(data);

    handleChangeField();
  };

  const handleLinha = async (e: any) => {
    if (e != undefined) {
      Promise.all([
        api.get(`geral/logoLinha/${e.value}`),
        api.get(`geral/selecaoPrenativo/${e.value}`),
        api.get("geral/selecaoUnidadePerfil")
      ]).then(([logoLinha, prenativo, unidadeValor]) => {
        setLogoLinha(logoLinha.data);
        setUnidadeValorizacao(unidadeValor.data);
        setPrenativo(prenativo.data);
      });
      //await fetchTemperaOptions(e.value);
    }
    handleChangeField();
  };

  const handleChangeField = () => {
    if (!produtoContext.entrouEmEdicao && !loadingValues) {
      produtoContext.setEntrouEmEdicao(true);
    }
  };

  return (
    <>
      <div className={"dimmer" + (loadingValues ? " active" : "")}>
        <div className="loader"></div>
        <div
          className={
            "dimmer-content" + (loadingValues ? " medium-box-loader" : "")
          }
        >
          <div className="form-row mt-4">
            <div className="form-group col">
              <label className="form-label">Status *</label>
              <Select
                name="status"
                options={status}
                placeholder="Selecione uma opção..."
                defaultValue={novo ? status[0] : ""}
                isDisabled={novo}
                onChange={handleChangeField}
              />
            </div>
            <div className="form-group col">
              <label className="form-label">Situação *</label>
              <Select
                name="situacao"
                options={situacao}
                placeholder="Selecione uma opção..."
                defaultValue={novo ? situacao[0] : ""}
              />
            </div>
            <Form.Group className="col">
              <Form.Label>Código *</Form.Label>
              <Input
                type="text"
                name="codigo"
                className="form-control"
                placeholder="Código..."
                onChange={handleChangeField}
              />
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Código Interno</Form.Label>
              <Input
                type="text"
                name="codigoInterno"
                className="form-control"
                placeholder="Código Interno..."
                onChange={handleChangeField}
              />
            </Form.Group>
          </div>

          <div className="form-row">
            <div className="form-group col">
              <label className="form-label">Referência *</label>
              <Select
                name="referencia"
                options={selectValues.referencia}
                onChange={(e) => {
                  handleReferenciaLinha(e);

                }}
                placeholder="Selecione uma opção..."
              />
            </div>
            <div className="form-group col">
              <label className="form-label">Linha *</label>
              <Select
                name="linha"
                options={linha}
                defaultValue={selectValues.linha}
                onChange={(e) => {
                  selectValues.formRef?.current?.clearField("prenativo");
                  handleLinha(e);
                }}
                placeholder="Selecione uma opção..."
              />
            </div>
          </div>

          <Form.Row>
            <Form.Group className="col">
              <Form.Label>Descrição *</Form.Label>
              <Input
                type="text"
                name="descricao"
                className="form-control"
                placeholder="Descrição..."
                onChange={handleChangeField}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group className="col">
              <Form.Label>Tamanho da Barra 1 *</Form.Label>
              <Input
                type="text"
                name="barra1"
                className="form-control"
                placeholder="Barra 1..."
                maxLength={5}
                onlyNumbers
                onChange={handleChangeField}
              />
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Tamanho da Barra 2</Form.Label>
              <Input
                type="text"
                name="barra2"
                className="form-control"
                placeholder="Barra 2..."
                maxLength={5}
                onlyNumbers
                onChange={handleChangeField}
              />
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Tamanho da Barra 3</Form.Label>
              <Input
                type="text"
                name="barra3"
                className="form-control"
                placeholder="Barra 3..."
                maxLength={5}
                onlyNumbers
                onChange={handleChangeField}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group className="col">
              <Form.Label>Peso *</Form.Label>
              <InputDecimal
                type="text"
                name="peso"
                className="form-control"
                placeholder="0,000..."
                displayType="input"
                onChange={handleChangeField}
                thousandSeparator={false}
                decimalSeparator=","
                valueIsNumericString
                decimalScale={3}
                fixedDecimalScale={true}
                isAllowed={(values: any) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue <= 999;
                }}
              />
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>IPI</Form.Label>
              <InputDecimal
                type="text"
                name="ipi"
                className="form-control"
                placeholder="0,00..."
                displayType="input"
                onChange={handleChangeField}
                thousandSeparator={false}
                decimalSeparator=","
                valueIsNumericString
                decimalScale={2}
                fixedDecimalScale={true}
                isAllowed={(values: any) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue <= 99;
                }}
              />
            </Form.Group>
          </Form.Row>

          <div className="form-row">
            <div className="form-group col">
              <label className="form-label">Classe / ID *</label>
              <Select
                name="classeId"
                options={classeIds}
                placeholder="Selecione uma opção..."
                onChange={handleChangeField}
              />
            </div>
            <div className="form-group col">
              <label className="form-label">Unidade de Valorização *</label>
              <Select
                name="unidadeValorizacao"
                options={unidadeValorizacao}
                placeholder="Selecione uma opção..."
                onChange={handleChangeField}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col">
              <label className="form-label">Prenativo *</label>
              <Select
                name="prenativo"
                options={prenativo}
                placeholder="Selecione uma opção..."
                defaultValue={selectValues.prenativo}
                onChange={handleChangeField}
              />
            </div>
            <div className="form-group col">
              <label className="form-label">Não Considerar Perda</label>
              <Select
                name="perda"
                options={simOuNao}
                placeholder="Selecione uma opção..."
                defaultValue={simOuNao[1]}
                onChange={handleChangeField}
              />
            </div>
            <div className="form-group col">
              <label className="form-label">Perfil Composto</label>
              <Select
                name="composto"
                options={perfilComposto}
                placeholder="Selecione uma opção..."
                defaultValue={perfilComposto[0]}
                onChange={handleChangeField}
              />
            </div>
          </div>

          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <a className="btn">Informações Adicionais</a>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <div className="form-row">
                    <div className="form-group col">
                      <label className="form-label">Liga</label>
                      <Select
                        name="liga"
                        options={selectValues.liga}
                        placeholder="Selecione uma opção..."
                        onChange={async (e) => {
                          handleLinha(e);
                        }}
                      />
                    </div>
                    <div className="form-group col">
                      <label className="form-label">Tempera</label>
                      <Select
                        name="tempera"
                        options={selectValues.tempera}
                        placeholder="Selecione uma opção..."
                        onChange={handleChangeField}
                      />
                    </div>
                  </div>

                  <Form.Row>
                    <Form.Group className="col">
                      <Form.Label>WX</Form.Label>
                      <InputDecimal
                        type="text"
                        name="wxt"
                        className="form-control"
                        placeholder="0,000..."
                        displayType="input"
                        onChange={handleChangeField}
                        thousandSeparator={false}
                        decimalSeparator=","
                        valueIsNumericString
                        decimalScale={3}
                        fixedDecimalScale={true}
                        isAllowed={(values: any) => {
                          const { formattedValue, floatValue } = values;
                          return formattedValue === "" || floatValue <= 999;
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="col">
                      <Form.Label>JX</Form.Label>
                      <InputDecimal
                        type="text"
                        name="jxt"
                        className="form-control"
                        placeholder="0,000..."
                        displayType="input"
                        onChange={handleChangeField}
                        thousandSeparator={false}
                        decimalSeparator=","
                        valueIsNumericString
                        decimalScale={3}
                        fixedDecimalScale={true}
                        isAllowed={(values: any) => {
                          const { formattedValue, floatValue } = values;
                          return formattedValue === "" || floatValue <= 999;
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group className="col">
                      <Form.Label>WY</Form.Label>
                      <InputDecimal
                        type="text"
                        name="wy"
                        className="form-control"
                        placeholder="0,000..."
                        displayType="input"
                        onChange={handleChangeField}
                        thousandSeparator={false}
                        decimalSeparator=","
                        valueIsNumericString
                        decimalScale={3}
                        fixedDecimalScale={true}
                        isAllowed={(values: any) => {
                          const { formattedValue, floatValue } = values;
                          return formattedValue === "" || floatValue <= 999;
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="col">
                      <Form.Label>JY</Form.Label>
                      <InputDecimal
                        type="text"
                        name="jy"
                        className="form-control"
                        placeholder="0,000..."
                        displayType="input"
                        onChange={handleChangeField}
                        thousandSeparator={false}
                        decimalSeparator=","
                        valueIsNumericString
                        decimalScale={3}
                        fixedDecimalScale={true}
                        isAllowed={(values: any) => {
                          const { formattedValue, floatValue } = values;
                          return formattedValue === "" || floatValue <= 999;
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group className="col">
                      <Form.Label>Perímetro Total</Form.Label>
                      <InputDecimal
                        type="text"
                        name="perimetroTotal"
                        className="form-control"
                        placeholder="0,000..."
                        displayType="input"
                        onChange={handleChangeField}
                        thousandSeparator={false}
                        decimalSeparator=","
                        valueIsNumericString
                        decimalScale={3}
                        fixedDecimalScale={true}
                        isAllowed={(values: any) => {
                          const { formattedValue, floatValue } = values;
                          return formattedValue === "" || floatValue <= 999;
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="col">
                      <Form.Label>Perímetro de Tratamento</Form.Label>
                      <InputDecimal
                        type="text"
                        name="perimetroTratamento"
                        className="form-control"
                        placeholder="0,000..."
                        displayType="input"
                        onChange={handleChangeField}
                        thousandSeparator={false}
                        decimalSeparator=","
                        valueIsNumericString
                        decimalScale={3}
                        fixedDecimalScale={true}
                        isAllowed={(values: any) => {
                          const { formattedValue, floatValue } = values;
                          return formattedValue === "" || floatValue <= 999;
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    </>
  );
};
export default PerfilTabGeral;
