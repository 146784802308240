import React, { useContext, useRef } from "react";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { ToastContext } from "../../../../contexts/toast/toastContext";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";

import TabGeral from "./TabGeral";
import TabAtualizacoes from "./TabAtualizacoes";
import TabPrecos from "./TabPrecos";
import TabAplicabilidades from "./TabAplicabilidades";
import { IFormGeral } from "../../../../common/interfaces/liberacoes.interfaces";
import { ToastErrorContext } from "../../../../contexts/toast/toastErrorContext";
import api from "../../../../services/api";
import { useAuth } from "../../../../hooks/auth";
import { toast } from "react-toastify";

interface ILocation {
  pathname: string,
  state: any
}

const LiberacaoAlumisoft: React.FC<any> = () => {

  // Importação de Dados
  const location: ILocation = useLocation();

  const { user } = useAuth();

  // Refs
  const formRef = useRef<FormHandles>(null);

  // Context
  const toastContext = useContext(ToastContext);
  const errorContext = useContext(ToastErrorContext);

  // Handles
  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handleSubmit: SubmitHandler = async (dadosGeral: IFormGeral) => {

    try {
      dadosGeral.numeroSerie = location.state.serial;
      await api.post('serial', dadosGeral);

      toast.success("A ação foi concluída.");
    } catch (err) {
      errorContext.notification(err);
    }
  };

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title">
                  <Link to="/liberacoes" className="mr-1">Liberações</Link> / Liberação Alumisoft
                </h2>
              </div>

              {user?.role.indexOf("ConsultaProduto") === -1 && (
                <span className="col-auto ml-auto pr-0">
                  <Button variant="white" onClick={handleSave}>
                    Salvar
                  </Button>
                </span>
              )}

              <div className="card mt-3 p-0">
                <div className="card-body">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="geral"
                  >
                    <Row>
                      <Col className="col-3">
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link
                              className="font-weight-bold"
                              eventKey="geral"
                            >
                              Geral
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              className="font-weight-bold"
                              eventKey="atualizacoes"
                            >
                              Pct Atualizações
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              className="font-weight-bold"
                              eventKey="precos"
                            >
                              Tabela de Preços
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              className="font-weight-bold"
                              eventKey="aplicabilidade"
                            >
                              Aplicabilidade
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col>
                        <Form ref={formRef} onSubmit={handleSubmit}>
                          <Tab.Content>
                            <Tab.Pane eventKey="geral">
                              <TabGeral dados={location.state} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="atualizacoes">
                              <TabAtualizacoes dados={location.state} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="precos">
                              <TabPrecos dados={location.state} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="aplicabilidade">
                              <TabAplicabilidades dados={location.state} />
                            </Tab.Pane>
                          </Tab.Content>
                        </Form>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiberacaoAlumisoft;
