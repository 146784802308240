import { Desenhador } from "@alumisoft/package_desenhador";
import { DataTypeDesenhador } from "../common/interfaces/projetosCemWeb";
import { useToggleDisplay } from "./useToogleDisplayDesenhador";
import { Button } from "react-bootstrap";

export interface ComponentePortalDesenhadorProps {
	handleDrawData?: (data: DataTypeDesenhador) => void;
	loadJSON?: string;
	variables?: {
		L: number;
		H: number;
	};
}

export function useComponenteDesenhador({
	handleDrawData,
	loadJSON,
	variables,
}: ComponentePortalDesenhadorProps) {
	const { isDesenhadorOpen, setIsDesenhadorOpen, toggleDisplayDesenhador } =
		useToggleDisplay();
	const ComponentePortalDesenhador = () => {
		return (
			<>
				<div className="voltar-desenhador">
					<Button
						title="Projetos CEM WEB"
						variant="primary"
						className="button-voltar-desenhador"
						onClick={() => {
							toggleDisplayDesenhador(false);
						}}
					>
						VOLTAR
					</Button>
				</div>
				<Desenhador
					retrieveDrawData={(data) => {
						console.log(data)
						handleDrawData?.(data);
						toggleDisplayDesenhador(false);
					}}
					loadJSON={loadJSON}
					variables={variables}
				/>
			</>
		);
	};
	const divPortal = document.getElementById("desenhador");

	return {
		ComponentePortalDesenhador,
		divPortal,
		isDesenhadorOpen,
		setIsDesenhadorOpen,
		toggleDisplayDesenhador,
	};
}
