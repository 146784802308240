import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { FormControl } from "react-bootstrap";
import ReactSelect from "react-select";

import api from "../../../services/api";

import { IFiltro } from "../../../common/interfaces/produtos.interfaces";
import { ProdutoContext } from "../../../contexts/produtos/produtosContext";
import { useAuth } from "../../../hooks/auth";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface IFiltrosProdutos {
  emEdicao: boolean;
  fetchData: (filtro: IFiltro, modoEdicao: boolean) => void;
}

const FiltrosProdutos: React.FC<IFiltrosProdutos> = (props) => {
  const situacao = [
    { label: "Em edição", value: "1" },
    { label: "Em homologação", value: "2" },
    { label: "Disponível para Mercado", value: "3" },
    { label: "Indisponível para Mercado", value: "4" },
  ];

  const status = [
    { label: "Ativo", value: "1" },
    { label: "Descontinuado", value: "2" },
  ];

  // Contexts
  const produtoContext = useContext(ProdutoContext);
  const { user } = useAuth();

  // States
  const query = useQuery();
  const [referencias, setReferencias] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [countReferencias, setCountReferencias] = useState(0);
  const [linhas, setLinhas] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);
  const [tipoMateriais, setTipoMateriais] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);
  const [loadedFilters, setLoadedFilters] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [localFilters, setLocalFilters] = useState<IFiltro>({
    busca: "",
    tipoProduto: "",
    situacao: { label: "", value: "" },
    status: { label: "", value: "" },
    referencia: { label: "", value: "" },
    linha: { label: "", value: "" },
  });

  const [buscaState, setBuscaState] = useState("");
  const [tipoState, setTipoState] = useState<{
    label: string;
    value: number;
  }>();
  const [situacaoState, setSituacaoState] = useState<{
    label: string;
    value: string;
  }>();
  const [statusState, setStatusState] = useState<{
    label: string;
    value: string;
  }>();
  const [referenciaState, setReferenciaState] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [linhaState, setLinhaState] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);

  const saveFiltersToLocalStorage = (filters: any) => {
    localStorage.setItem("filters", JSON.stringify(filters));
  };

  //Effects
  useEffect(() => {
    if (query.get("busca")) {
      handleChangeBusca(query.get("busca") || "");
    }
    if (query.get("tipoProduto")) {
      handleChangeProdutos(Number(query.get("tipoProduto")));
    }
    if (query.get("situacao")) {
      handleChangeSituacao(Number(query.get("situacao") || ""));
    }
    if (query.get("status")) {
      handleChangeStatus(Number(query.get("status") || ""));
    }

    if (query.get("referencia")) {
      const referenciasValues = query
        .getAll("referencia")
        .map((item) => Number(item));
      const valueReferencia: { label: string; value: string }[] = [];
      referenciasValues.forEach((itemReferencia) => {
        const referenciasFinds = referencias.find(
          (item: any) => item.value === itemReferencia
        );
        if (referenciasFinds) {
          valueReferencia.push(referenciasFinds);
        }
      });
      if (valueReferencia.length) {
        handleChangeReferencia(valueReferencia);
      }
    }

    if (!loadedFilters) {
      const fetchData = async () => {
        try {
          const selectReferencias = await api.get(
            "geral/selecaoReferenciaFiltro"
          );
          setReferencias(selectReferencias.data);

          const selectTipoMaterial = await api.get("geral/selecaoTipoMaterial");
          setTipoMateriais(selectTipoMaterial.data);
        } catch (error) {
          console.error("Erro ao carregar dados:", error);
        }
      };

      fetchData();

      const savedFilters = localStorage.getItem("filters");
      if (savedFilters) {
        const parsedFilters = JSON.parse(savedFilters);
        setSearchValue(parsedFilters.busca || "");
      } else {
        saveFiltersToLocalStorage(localFilters);
      }

      setLoadedFilters(true);
    }
  }, [loadedFilters, query]);

  useEffect(() => {
    if (query.get("linha")) {
      const linhasValues = query.getAll("linha").map((item) => Number(item));

      const valueLinhas: { label: string; value: number }[] = [];
      linhasValues.forEach((itemLinha) => {
        const linhaFind = linhas.find((item: any) => item.value === itemLinha);
        if (linhaFind) {
          valueLinhas.push(linhaFind);
        }
      });
      if (valueLinhas.length) {
        handleChangeLinha(valueLinhas);
      }
    }
  }, [linhas, query]);

  // Handles
  const handleChangeBusca = useCallback(
    debounce(async (busca) => {
      if (busca) {
        produtoContext.setFiltro((prevState: any) => ({
          ...prevState,
          busca: busca,
        }));

        if (busca) {
          setBuscaState(busca);
        }

        saveFiltersToLocalStorage({
          ...produtoContext.filtro,
          busca: busca,
        });
        return;
      }
      saveFiltersToLocalStorage({
        ...produtoContext.filtro,
        busca: "",
      });
      setBuscaState("");
      produtoContext.setFiltro((prevState: any) => ({
        ...prevState,
        busca: "",
      }));
    }, 0),
    []
  );

  const handleChangeProdutos = (tipoValue: number | null) => {
    if (tipoValue !== null && tipoValue !== undefined) {
      produtoContext.setFiltro((prevState: any) => ({
        ...prevState,
        tipoProduto: tipoValue,
      }));

      const tipoResult = tipoMateriais.find((item) => item.value === tipoValue);
      if (tipoResult) {
        setTipoState(tipoResult);
      }

      saveFiltersToLocalStorage({
        ...produtoContext.filtro,
        tipoProduto: tipoValue,
      });
      return;
    }
    saveFiltersToLocalStorage({
      ...produtoContext.filtro,
      tipoProduto: "",
    });
    setTipoState(undefined);
    produtoContext.setFiltro((prevState: any) => ({
      ...prevState,
      tipoProduto: "",
    }));
  };

  const handleChangeSituacao = (situacaoValue: number | null) => {
    if (situacaoValue !== null && situacaoValue !== undefined) {
      produtoContext.setFiltro((prevState: any) => ({
        ...prevState,
        situacao: situacaoValue,
      }));
      const situacaoResult = situacao.find(
        (item) => item.value === situacaoValue.toString()
      );
      if (situacaoResult) {
        setSituacaoState(situacaoResult);
      }

      saveFiltersToLocalStorage({
        ...produtoContext.filtro,
        situacao: situacaoValue,
      });
      return;
    }
    saveFiltersToLocalStorage({
      ...produtoContext.filtro,
      situacao: "",
    });
    setSituacaoState(undefined);
    produtoContext.setFiltro((prevState: any) => ({
      ...prevState,
      situacao: "",
    }));
  };

  const handleChangeStatus = (statusValue: number) => {
    if (statusValue !== null && statusValue !== undefined) {
      produtoContext.setFiltro((prevState: any) => ({
        ...prevState,
        status: statusValue,
      }));
      const statusResult = status.find(
        (item) => item.value === (statusValue?.toString() || "")
      );
      if (statusResult) {
        setStatusState(statusResult);
      }
      saveFiltersToLocalStorage({
        ...produtoContext.filtro,
        status: statusValue,
      });
      return;
    }
    saveFiltersToLocalStorage({
      ...produtoContext.filtro,
      status: "",
    });
    setStatusState(undefined);
    produtoContext.setFiltro((prevState: any) => ({
      ...prevState,
      status: "",
    }));
  };

  const handleChangeReferencia = async (
    referenciaValue: { label: string; value: string }[]
  ) => {
    const referenciaFinal: any = [];

    await Promise.all(
      referenciaValue.map(async (obj) => {
        referenciaFinal.push(obj.value);
      })
    );

    const { data } = await api.post(`linha/selectMulti`, referenciaFinal);
    setLinhas(data);

    setCountReferencias(referenciaFinal.length);
    if (referenciaValue !== null && referenciaValue !== undefined) {
      produtoContext.setFiltro((prevState: any) => ({
        ...prevState,
        referencia: referenciaFinal,
      }));

      setReferenciaState(referenciaValue);
      saveFiltersToLocalStorage({
        ...produtoContext.filtro,
        referencia: referenciaFinal,
      });
      return;
    }
    saveFiltersToLocalStorage({
      ...produtoContext.filtro,
      referencia: "",
    });
    setReferenciaState([]);
    produtoContext.setFiltro((prevState: any) => ({
      ...prevState,
      referencia: "",
    }));
  };

  const handleChangeLinha = async (
    linhaValue: { label: string; value: number }[]
  ) => {
    const linhaFinal: any = [];
    await Promise.all(
      linhaValue.map(async (obj) => {
        linhaFinal.push(obj.value);
      })
    );
    if (linhaValue !== null && linhaValue !== undefined) {
      produtoContext.setFiltro((prevState: any) => ({
        ...prevState,
        linha: linhaFinal,
      }));

      setLinhaState(linhaValue);
      saveFiltersToLocalStorage({
        ...produtoContext.filtro,
        linha: linhaFinal,
      });
      return;
    }
    saveFiltersToLocalStorage({
      ...produtoContext.filtro,
      linha: "",
    });
    setLinhaState([]);
    produtoContext.setFiltro((prevState: any) => ({
      ...prevState,
      linha: "",
    }));
  };

  function havePermission(permission: string): boolean {
    var isValid = user?.role.indexOf(permission) !== -1;
    return isValid;
  }

  if (user?.role.indexOf("ConsultaProduto") === -1) {
    status.push({ label: "Arquivo Morto", value: "5" });
    status.push({ label: "Novo Portfólio", value: "7" });
  }

  return (
    <>
      <h2>Filtros</h2>
      {!havePermission("ConsultaProduto") ? (
        <>
          <div className="subheader mb-3 mt-3">
            Busca:
            <FormControl
              type="text"
              placeholder="Código ou Descrição"
              value={searchValue}
              className="mt-1 mb-2 w-100"
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleChangeBusca(e.target.value);
              }}
            />
          </div>
          <div className="subheader mb-3">
            Produtos:
            <ReactSelect
              name="tipoProduto"
              placeholder="Todos"
              value={tipoState}
              options={tipoMateriais}
              isClearable={true}
              onChange={(e: any) => {
                handleChangeProdutos(e?.value);
              }}
              // onKeyDown={(e: any) => {
              //   if (e.key === "Enter")
              //     props.fetchData(produtoContext.filtro, props.emEdicao);
              // }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="subheader mb-3">
            Produtos:
            <ReactSelect
              name="tipoProduto"
              placeholder="Todos"
              options={tipoMateriais}
              value={tipoState}
              isClearable={true}
              onChange={(e: any) => {
                handleChangeProdutos(e?.value);
              }}
              onKeyDown={(e: any) => {
                if (e.key === "Enter")
                  props.fetchData(produtoContext.filtro, props.emEdicao);
              }}
            />
          </div>
          <div className="subheader mb-3 mt-3">
            Busca:
            <FormControl
              type="text"
              value={buscaState}
              placeholder="Buscar Código"
              className="mt-1 mb-2 w-100"
              onChange={(e) => handleChangeBusca(e.target.value)}
              onKeyDown={(e: any) => {
                if (e.key === "Enter")
                  props.fetchData(localFilters, props.emEdicao);
              }}
            />
          </div>
        </>
      )}

      {user?.role.indexOf("ConsultaProduto") === -1 && (
        <div className="subheader mb-3" hidden={!props.emEdicao}>
          Situação:
          <ReactSelect
            placeholder="Todos"
            isClearable={true}
            options={situacao}
            value={situacaoState}
            onChange={(e: any) => {
              handleChangeSituacao(e?.value);
              console.log("e", e);
            }}
            // onKeyDown={(e: any) => {
            //   if (e.key === "Enter")
            //     props.fetchData(produtoContext.filtro, props.emEdicao);
            // }}
          />
        </div>
      )}

      <div className="subheader mb-3">
        Status:
        <ReactSelect
          placeholder="Ativos"
          isClearable={true}
          options={status}
          value={statusState}
          onChange={(e: any) => handleChangeStatus(e?.value)}
          onKeyDown={(e: any) => {
            if (e.key === "Enter")
              props.fetchData(produtoContext.filtro, props.emEdicao);
          }}
        />
      </div>

      <div className="subheader mb-3">
        Referência:
        <ReactSelect
          name="referencia"
          placeholder="Todos"
          value={referenciaState}
          options={referencias}
          onChange={(e: any) => {
            handleChangeReferencia(e);
          }}
          noOptionsMessage={() => "Sem dados"}
          isMulti={true}
          onKeyDown={(e: any) => {
            if (e.key === "Enter")
              props.fetchData(produtoContext.filtro, props.emEdicao);
          }}
        />
      </div>

      <div className="subheader mb-3">
        Linha:
        <ReactSelect
          name="linha"
          placeholder="Todos"
          value={linhaState}
          options={linhas}
          onChange={(e: any) => {
            handleChangeLinha(e);
          }}
          noOptionsMessage={() => "Sem dados"}
          isMulti={true}
          onKeyDown={(e: any) => {
            if (e.key === "Enter")
              props.fetchData(produtoContext.filtro, props.emEdicao);
          }}
        />
      </div>
    </>
  );
};
export default memo(FiltrosProdutos);
