import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Form } from "@unform/web";
import { Select, File, Input } from "../../../../components/Form";

import FilterTableApi from "../../../../components/DataTable/filterTableApi-BuscaExt";
import DataNotFound from "../../../../components/DataNotFound";
import api from "../../../../services/api";

import { ToastContext } from "../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../contexts/sweetAlert/alertContext";

import { SubmitHandler, FormHandles } from "@unform/core";
import getValidationErrors from "../../../../helpers/getValidateErrors";
import { getSingleErrorMessage } from "../../../../helpers/getSingleErrorMessage";
import * as Yup from "yup";

import { IFormPacoteHomologacao, IListPacoteHomologacao } from "../../../../common/interfaces/referencias.interfaces";
import { ToastErrorContext } from "../../../../contexts/toast/toastErrorContext";
import { simOuNao } from "../../../../components/Form/Select/options";
import { toast } from "react-toastify";
import { IResult } from "../../../../common/interfaces/IResult";
import { AxiosResponse } from "axios";


const TabHomologacao: React.FC = () => {

  //Params
  const { pId }: any = useParams();

  //Constants
  const tipos = [
    { label: "Referência", value: 1 },
    { label: "Linha", value: 2 },
    { label: "Acabamento", value: 3 },
    { label: "Tipologia", value: 0 },
  ];

  const SUPPORTED_FORMATS = [
    "application/zip",
    "application/x-zip-compressed",
  ];

  const message = "Campo obrigatório";
  const schema = Yup.object().shape({

    tipo: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    linha: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    arquivo: Yup.mixed()
      .when('tipo', {
        is: (val?: any) => val?.label == 'Tipologia',
        then: Yup.mixed().required('Selecione um arquivo para upload')
          .when('arquivo', {
            is: (val?: any) => val != undefined,
            then: Yup.mixed().test('fileType', "O arquivo deve ser .zip", value => SUPPORTED_FORMATS.includes(value.type)),
            otherwise: Yup.mixed(),
          }),
        otherwise: Yup.mixed(),
      })
  });

  // Refs
  const formHomologacaoRef = useRef<FormHandles>(null);

  //Fields Typed
  const DataTableTS: any = FilterTableApi;

  //States
  const [tableData, setTableData] = useState<IListPacoteHomologacao[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [reloadTable, setReloadTable] = useState(false);
  const [selected, setSelected] = useState({} as IFormPacoteHomologacao);
  const [show, setShow] = useState(false);
  const [tipoSelect, setTipoSelect] = useState<any>();
  const [pacoteLoading, setPacoteLoading] = useState(false);
  const [linhas, setLinhas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
  const [pacotePronto, setPacotePronto] = useState(false);

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);

  //Callbacks
  const fetchData = useCallback(async (globalFilter) => {
    api.get(`/referencia/listaHomologacaoPortfolioCEM/${pId}/${globalFilter}`)
      .then((response) => {
        setTableData(response.data);
      }).catch((error) => toast.error(error.message)).finally(() => setTableLoading(false));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      api.get(`/referencia/linha/${pId}`)
        .then((response) => {
          setLinhas(response.data);
        }).catch((error) => toast.error(error.message)).finally(() => setTableLoading(false));
    };

    fetchData();
  }, []);

  //Handles
  const handleNew = () => {
    setShow(true);
    setSelected({} as IFormPacoteHomologacao);
    setPacotePronto(false);
  };

  const handleEdit = async ({ id }: any) => {
    const { data } = await api.get(`/referencia/atualizacaoPortfolioCEM/${id}`);
    console.log(data)
    setSelected(data);
    setTipoSelect(data.tipo.label);
    setShow(true);
    setPacotePronto(data.pacoteHomologadorGerado);
    setBotaoDesabilitado(!data.pacoteHomologadorGerado);
  };

  const handleDelete = async ({ id, tipo, linha }: IListPacoteHomologacao) => {

    const title = `Excluir pacote "${tipo}"${tipo == 'Referência' ? '?' : ' - "' + linha + '"?'}`;
    const message = "Esse pacote será excluído, isso não pode ser desfeito.";

    const { data: { alerta } } = await api.get(`referencia/acaoAlertaRemoverPacote/${id}`);

    if (alerta) {
      sweetAlertContext.warning("Atenção", `Este pacote está liberado para uso de clientes. Deseja eliminar mesmo assim?`, async () => {
        try {
          await handleRemoverPacote(id);
          sweetAlertContext.close();
        } catch (error) {
          toastContext.notification(message, "warn");
        }
      });
    }
    else {
      sweetAlertContext.danger(title, message, async () => {
        try {
          await handleRemoverPacote(id);
          sweetAlertContext.close();
        } catch (error) {
          toastContext.notification(message, "warn");
        }
      });
    }
  }

  const handleRemoverPacote = async (id: any) => {
    let PORTFOLIO_DISPONIVEL = false;

    api.post(`/referencia/RemoverAtualizacaoPortfolioCEM/${id}/${PORTFOLIO_DISPONIVEL}`)
      .then((response: AxiosResponse<IResult>) => {
        if (response.data.isValid) {
          setTableData((prevState) =>
            prevState.filter(function (item) {
              return item.id !== id;
            })
          );
          toast.success(response.data.message)
        } else {
          toast.error(response.data.errors[0].errorMessage)
        }
      }).catch((error) => toast.error(error.message)).finally(() => setTableLoading(false));
  }

  const handleClose = () => {
    setShow(false);
    setTipoSelect(null);
  }

  const handleSelectTipo = (e: any) => {
    setTipoSelect(e.label);
  }

  const handleGerarPacote = () => {
    formHomologacaoRef.current?.submitForm();
  };

  const handleSubmit: SubmitHandler<IFormPacoteHomologacao> = async (pacote) => {
    try {
      formHomologacaoRef.current?.setErrors({});
      await schema.validate(pacote, { abortEarly: false });

      //Passou pela validação      
      setPacoteLoading(true);
      pacote.idReferencia = pId;
      var data = new FormData();
      if (pacote.id != "") {
        data.append("id", pacote.id);
      }
      if (pacote.arquivo != null) {
        data.append("arquivo", pacote.arquivo);
      }
      data.append("idReferencia", pacote.idReferencia.toString());
      data.append("idTipo", pacote.tipo.value);
      if (pacote.linha != null) {
        data.append("idLinha", pacote.linha.value.toString());
        data.append("nomeLinha", pacote.linha.label);
      }

      data.append("arquivoComum", pacote.arquivoComum.value ? "true" : "false");
      //data.append("pacoteHomologadorGerado", "true");
      data.append("nomeReferencia", pacote.tipo.label);

      await api.post('referencia/SalvarAtualizacaoPortfolioCEM2', data)
        .then(response => {
          if(response){
            handleAfterSubmit(response, pacote);
          }
        }).catch(error => {
          handleErrorSubmit(error)
        }).finally(() => {
          setPacoteLoading(false);
        });

    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      setPacoteLoading(false);
      formHomologacaoRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleAfterSubmit = ({ data }: any, pacote: any) => {
    console.log(data)
    pacote.id = data.data.idAtualizacaoPortfolio;
    pacote.urixmlMaterial = data.data.urixmlMaterial ?? "";
    pacote.urixmlTipologia = data.data.urixmlTipologia ?? "";
    pacote.urixmlAcabamento = data.data.urixmlAcabamento ?? "";
    setBotaoDesabilitado(false);
    setPacoteLoading(false);
    setSelected(pacote);
    setPacotePronto(pronto => true);
    setReloadTable(reload => !reload);
    toast.success("A ação foi concluída.");
  }

  const handleErrorSubmit = (error: any) => {
    if (error.response.data) {
      toastContext.notification(error.response.data.errorMessage, "error");
    } else {
      toastContext.notification(error.message, "error");
    }
  }

  const handleDisponibilizarMercado = () => {
    const title = `Tem certeza?`;
    const message = "O pacote será disponibilizado para o mercado.";
    sweetAlertContext.warning(title, message, async () => {
      try {
        setBotaoDesabilitado(true)
        await api.post(`referencia/homologarAtualizacaoPortfolioCEM/${selected.id}`);
        toast.success("A ação foi concluída.");
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "Tipo",
        accessor: "tipo",
        display: "align-middle",
      },
      {
        Header: "Possui arquivo Comum",
        accessor: "arquivoComum",
        display: "align-middle",
      },
      {
        Header: "Linha",
        accessor: "linha",
        display: "align-middle",
      },
      {
        Header: "Atualização",
        accessor: "atualizacao",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({ row }: { row: any }) => (
          <span className="d-none d-sm-inline">
            <Button variant="white" onClick={() => handleEdit(row.original)} className="btn btn-ghost-dark p-2">
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button variant="white" onClick={() => handleDelete(row.original)} className="btn btn-ghost-dark p-2">
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="mt-1">
        <Row>
          <span className="col-auto ml-auto">
            <Button onClick={handleNew} variant="white">
              Novo Pacote
            </Button>
          </span>
          <div className="col-auto ml-0 pr-0">
            <input
              className="form-control"
              type="text"
              placeholder="Busca..."
              value={searchTerm || ""}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </Row>
        <Row className="mt-3">
          <div className={'dimmer' + (tableLoading ? ' active' : '')}>
            <div className="loader"></div>
            <div className={"dimmer-content" + (tableLoading ? ' small-box-loader' : '')} >

              {tableData && (
                <DataTableTS
                  filter={searchTerm}
                  columns={columns}
                  fetchData={fetchData}
                  reload={reloadTable}
                  data={tableData}
                  bordered={false}
                  initialState={{
                    sortBy: [
                      {
                        id: "id",
                        desc: false,
                      },
                    ],
                  }}
                />
              )}
              <DataNotFound
                visible={
                  tableLoading == false ? tableData?.length === 0 : false
                }
              ></DataNotFound>
            </div>
          </div>
        </Row>
      </div>


      {/* Modal Homologação */}
      <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Pacote para Homologação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'dimmer ' + (pacoteLoading ? "active" : "")}>
            <div className="loader"></div>
            <div className="dimmer-content">
              <div className="row mb-3 pr-0">

                <span className="col-auto ml-auto">
                  <button className={pacotePronto ? "btn btn-white" : "btn btn-white disabled"}
                    onClick={handleDisponibilizarMercado}
                    disabled={botaoDesabilitado}>Disponibilizar Mercado</button>
                </span>
              </div>

              <Form ref={formHomologacaoRef} initialData={selected} onSubmit={handleSubmit}>
                <Input type="hidden" name="id" defaultValue={selected.id} />
                <div className="form-row">
                  <div className="form-group col">
                    <label className="form-label">Tipo de Pacote</label>
                    <Select
                      name="tipo"
                      options={tipos}
                      placeholder="Selecione uma opção..."
                      onChange={(e: any) => handleSelectTipo(e)}
                      isDisabled={selected.id != null}
                      defaultValue={selected?.tipo}
                    ></Select>
                  </div>
                  <div className="form-group col">
                    <label className="form-label">Incluir arquivo comum?</label>
                    <Select
                      name="arquivoComum"
                      placeholder="Selecione uma opção..."
                      options={simOuNao}
                      defaultValue={selected.arquivoComum ?? simOuNao[1]}
                    ></Select>
                  </div>
                </div>

                {tipoSelect != 'Referência' && tipoSelect != 'Acabamento' && tipoSelect != undefined &&
                  <div className="form-row">
                    <div className="form-group col">
                      <label className="form-label">Selecione a Linha</label>
                      <Select
                        name="linha"
                        options={linhas}
                        placeholder="Selecione uma opção..."
                        defaultValue={selected?.linha}
                      ></Select>
                    </div>
                  </div>
                }

                {tipoSelect == 'Tipologia' &&

                  <div className="form-row">
                    <div className="form-group col">
                      <label className="form-label">Selecione o Arquivo</label>
                      <File name="arquivo" placeholder="Procurar arquivo .zip..." />
                    </div>
                  </div>

                }
                {tipoSelect == 'Tipologia' &&
                  <div className="form-row mt-3">
                    <div className="col">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-auto">
                              <span
                                className="bg-blue text-white avatar"
                                style={{ fontSize: "0.7rem" }}
                              >
                                <strong>.zip</strong>
                              </span>
                            </div>
                            <div className="col-6">
                              <div>XML de Tipologia</div>
                              <div className="text-muted">XML_Tipologia_{selected.linha?.label}.zip</div>
                            </div>
                            <div className="col text-right">
                              <a href={selected.urixmlTipologia} className="btn btn-ghost-dark p-2">
                                {/* {"XML_Tipologia_" + selected.linha.label + ".zip"} */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="12" y1="11" x2="12" y2="17" /><polyline points="9 14 12 17 15 14" /></svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {selected.urixmlMaterial &&
                  <div className="form-row mt-3">
                    <div className="col">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-auto">
                              <span className="bg-blue text-white avatar" style={{ fontSize: "0.7rem" }}>
                                <strong>.zip</strong>
                              </span>
                            </div>
                            <div className="col-6">
                              <div>XML de Material</div>
                              <div className="text-muted">XML_Material{selected.linha != null && selected.linha?.value != 0 ? "_" + selected.linha.label : ""}.zip</div>
                            </div>
                            <div className="col text-right">
                              <a href={selected.urixmlMaterial} download={"XML_Material" + (selected.linha != null && selected.linha?.value != 0 ? "_" + selected.linha.label : "") + ".zip"} target="_blank" className="btn btn-ghost-dark p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="12" y1="11" x2="12" y2="17" /><polyline points="9 14 12 17 15 14" /></svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {selected.urixmlAcabamento &&
                  <div className="form-row mt-3">
                    <div className="col">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-auto">
                              <span className="bg-blue text-white avatar" style={{ fontSize: "0.7rem" }}>
                                <strong>.zip</strong>
                              </span>
                            </div>
                            <div className="col-6">
                              <div>XML de Acabamento</div>
                              <div className="text-muted">XML_Acabamento{selected.linha != null && selected.linha?.value != 0 ? "_" + selected.linha.label : ""}.zip</div>
                            </div>
                            <div className="col text-right">
                              <a href={selected.urixmlAcabamento} download={"XML_Acabamento" + (selected.linha != null && selected.linha?.value != 0 ? "_" + selected.linha.label : "") + ".zip"} target="_blank" className="btn btn-ghost-dark p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="12" y1="11" x2="12" y2="17" /><polyline points="9 14 12 17 15 14" /></svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button disabled={pacoteLoading} variant="primary" onClick={handleGerarPacote}>
            Gerar Pacote
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );
};

export default TabHomologacao;
