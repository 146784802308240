import React, { useEffect, useState, useRef, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DualList from "../../../../components/DualList";
import { Select } from "../../../../components/Form";
import history from "../../../../helpers/history";
import api from "../../../../services/api";
import { SweetAlertContext } from "../../../../contexts/sweetAlert/alertContext";
import { toast } from "react-toastify";


const TabRecursos: React.FC = () => {
  //URL Params
  const { pId, pString }: any = useParams();

  //Contexts
  const sweetAlertContext = useContext(SweetAlertContext);

  //States
  const [stateList, setStateList] = useState<any>([]);
  const [options, setOptions] = useState([]);
  const [empresaTipoSelect, setEmpresaTipoSelect] = useState<any>([]);
  const [recursoSelect, setRecursoSelect] = useState<number>(0);

  //ignora primeiro change setEmpresaTipoSelect
  const firstUpdate = useRef(true);

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get('/telasistema');
        const selectValues = await api.get('/geral/selecaoTipoEmpresa');
        
        setOptions(option => option = data);
        setEmpresaTipoSelect(selectValues.data);

        firstUpdate.current = false;
      } catch (error: any) {
        toast.error(error.message);
      }
    }

    fetchData();

  }, []);

  // Variables 
  const { selectedOption } = stateList;

  // Handles
  const handleProfile = () => {
    history.push(`/empresas/${pId}/${pString}/perfis`);
  };

  const handleSelectProfile = async (value: any) => {
    const param = { idEmpresa: pId, id: value };

    if (value != null && value != 0 && !firstUpdate.current) {
      await api.post("/empresa/salvarTipo", param);
    }

    const { data } = await api.get(`empresa/telaSistemaDisponivel/${pId}/${value}`);
    setStateList(data);
    setRecursoSelect(value);
  };

  const handleChangeRecursosList = async (selectedOption: any) => {
    setStateList({ selectedOption });

    console.log(selectedOption)

    const param = { idEmpresa: pId, idsRecurso: selectedOption, id: recursoSelect };

    const { data: { vinculoUsuario } } = await api.post("/empresa/acaoAlertaVinculoUsuario", param);

    if (vinculoUsuario) {
      sweetAlertContext.warning("Atenção", `Este recurso está liberado para um ou mais usuários. Deseja eliminar mesmo assim?`, async () => {
        await api.post("/empresa/salvarRecurso", param);
      }, async () => {
        const { data } = await api.get(`empresa/telaSistemaDisponivel/${pId}/${recursoSelect}`);
        setStateList(data);
      });
    } else
      await api.post("/empresa/salvarRecurso", param);
  };

  return (
    <>
      <Form.Row className="mt-4">
        <div className="col">
          <Form.Label>Perfil da Empresa</Form.Label>
          <Select name="perfil" placeholder="Perfil da Empresa" options={empresaTipoSelect} onChange={(e: any) => handleSelectProfile(e.value)} />
        </div>
        <div className="col-auto">
          <Button
            variant="white"
            style={{ marginTop: 27 }}
            onClick={() => handleProfile()}
          >
            Perfis
          </Button>
        </div>
      </Form.Row>
      <hr />
      <DualList
        className="mb-4"
        selected={selectedOption}
        options={options}
        onChange={handleChangeRecursosList}
        availableHeader="Recursos Disponíveis"
        selectedHeader="Recursos Selecionados"
      />
    </>
  );
};

export default TabRecursos;
