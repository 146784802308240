import React, { useState, useEffect, useMemo, useContext, useRef, useCallback } from "react";
import { Modal, Row, Button } from "react-bootstrap";
import { SwitchCheckbox } from "../../../../../components/Form";
import ReactSelect from "react-select";

import DataTableColumnCheckBox from "../../../../../components/DataTable/DataTableCheckbox";
import GlobalFilter from "../../../../../components/DataTable/GlobalFilter";
import FilterTable from "../../../../../components/DataTable/filterTable";
import PaginationTable from "../../../../../components/DataTable/paginationTable-BuscaExt";
import DataNotFound from "../../../../../components/DataNotFound";
import api from "../../../../../services/api";

import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";

import { IListLiberacaoAlumisoft, IListPacote, IListPacoteReferencia } from "../../../../../common/interfaces/liberacoes.interfaces";
import { IPaginacao } from "../../../../../common/interfaces/paginacao.interfaces";
import { ToastErrorContext } from "../../../../../contexts/toast/toastErrorContext";
import { useAuth } from "../../../../../hooks/auth";
import { toast } from "react-toastify";

interface IAtualizacoes {
  dados: IListLiberacaoAlumisoft
}

const TabAtualizacoes: React.FC<IAtualizacoes> = (props) => {

  //Fields Typed
  const DataTableTS: any = FilterTable;
  const DataTableTSModal: any = PaginationTable;
  const queryString = require('querystring');
  const { user } = useAuth();
  ;
  // Refs
  const formRef = useRef<FormHandles>(null);

  // Context
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);
  const sweetAlertContext = useContext(SweetAlertContext);

  //States
  const [tableData, setTableData] = useState<IListPacote[]>([]);
  const [tablePacoteData, setTablePacoteData] = useState<IListPacoteReferencia[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [referencia, setReferencia] = useState<any>(null);
  const [filter, setFilter] = useState('');
  const [filterReferencia, setFilterReferencia] = useState<number | undefined>();
  const [filterHomologador, setFilterHomologador] = useState<boolean | undefined>(false);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [reloadDataTable, setReloadDataTable] = useState(false);


  //Effects
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);
        const { data } = await api.get('referencia/PacoteSelect');
        setReferencia(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await api.get(`serial/ListaPortfolioCEMLiberacao/${props.dados.idSerial}`);
        setTableData(data);

        console.log(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    };

    fetch();
  }, [reloadDataTable]);

  //Callbacks
  const fetchData = useCallback(async (paginacao: IPaginacao) => {
    try {
      setTableLoading(true);

      console.log(paginacao);
      const { data: { dados, paginas } } = await api.get(`serial/listaPortfolioCEMDisponivel?idSerial=${props.dados.idSerial}&${queryString.stringify(paginacao)}`);

      setPageCount(paginas);
      setTablePacoteData(dados);

    } catch (error) {
    } finally {
      setTableLoading(false);
    }
  }, []);

  //Handles
  const handleClose = () => {
    setShow(false);
    setFilterReferencia(undefined);
    setFilterHomologador(false);
    setFilter('');
  }

  const handleShow = () => {
    setFilterHomologador(false);
    setShow(true);
  }

  const handleFiltroReferencia = (filtro?: number) => {
    setFilterReferencia(filtro);
  };

  const handleFiltroHomologador = (filtro?: boolean) => {
    setFilterHomologador(filtro);
  };

  const handleSubmit: SubmitHandler = async (dados) => {
    try {
      if (tableSelectedRows.length != 0) {

        dados.ItemsSelecionados = tableSelectedRows;
        dados.idSerial = props.dados.idSerial;
        dados.homologador = filterHomologador;

        await api.post('serial/salvarLiberacaoPorfolioCEM', dados);
        setReloadDataTable(reload => !reload);

        toast.success("A ação foi concluída.");
      }
      setShow(false);
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
    }
  };

  const handleDelete = ({ id, linha, referencia }: IListPacote) => {
    const title = `Excluir "${referencia + " - " + linha}"?`;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`serial/removerLiberacaoPorfolioCEM/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Tipo",
        accessor: "tipo",
        display: "align-middle",
      },
      {
        Header: "Referencia",
        accessor: "referencia",
        display: "align-middle",
      },
      {
        Header: "Linha",
        accessor: "linha",
        display: "align-middle",
      },
      {
        Header: "Data",
        accessor: "dataAtualizacao",
        display: "align-middle",
        render: (value: any) => {
          return (<>
            {value.dataAtualizacao ? new Date(value.dataAtualizacao).toLocaleDateString("dd/mm/yyyy") : ""}
          </>)
        }
      },
      {
        Header: "Homologador",
        accessor: "homologador",
        display: "align-middle",
      },
    ],
    []
  );

  if (user?.role.indexOf("ConsultaLiberacao") === -1) {
    const deleteColumn = {
      Header: "Ação",
      accessor: "acao",
      disableSortBy: true,
      display: "align-middle column-table-1b",
      Cell: ({ row: { original } }: { row: { original: IListPacote } }) => (
        <Button variant="ghost-dark" className="p-2" onClick={() => handleDelete(original)}>
          <i className="far fa-trash-alt icon m-0"></i>
        </Button>
      ),
    }

    columns.push(deleteColumn);
  }

  const columnsModal = useMemo(
    () => [
      {
        Header: "",
        accessor: "acao",
        display: "align-middle column-table-1b",
        Cell: ({ row }: any) => (
          <DataTableColumnCheckBox
            selectProps={row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: "Tipo",
        accessor: "tipo",
        display: "align-middle",
      },
      {
        Header: "Referência",
        accessor: "referencia",
        display: "align-middle",
      },
      {
        Header: "Linha",
        accessor: "linha",
        display: "align-middle",
      },
      {
        Header: "Data",
        accessor: "dataAtualizacao",
        display: "align-middle",
        render: (value: any) => {
          return (<>
            {value.dataAtualizacao ? new Date(value.dataAtualizacao).toLocaleDateString("dd/mm/yyyy") : ""}
          </>)
        }
      },
    ],
    []
  );

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row px-3 align-items-center">
            <div className="col">
              <h5 className="mb-2">Razão Social:</h5>
              <p className="mb-0">{props.dados.razaoSocial}</p>
            </div>
            <div className="col">
              <h5 className="mb-2">Nome Fantasia:</h5>
              <p className="mb-0">{props.dados.nomeFantasia}</p>
            </div>
            <div className="col-auto">
              <h5 className="mb-2">Serial:</h5>
              <p className="mb-0">{props.dados.serial}</p>
            </div>
          </div>
        </div>
      </div>
      <Row className="mt-4">
        <div className="col-auto ml-auto">
          {user!.role.indexOf("Liberacao") >= 0 && (
            <Button variant="white" onClick={handleShow}>
              Adicionar
            </Button>
          )}
        </div>
        <div className="col-auto">
          <input
            className="form-control"
            type="text"
            placeholder="Busca..."
            value={searchTerm || ""}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </Row>
      <Row className="mt-3">
        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' small-box-loader' : '')} >

            {tableData && (
              <DataTableTS
                filter={searchTerm}
                columns={columns}
                data={tableData}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={
                tableLoading == false ? tableData?.length === 0 : false
              }
            ></DataNotFound>
          </div>
        </div>
      </Row>

      {/* Modal Adicionar Pacote de Atualização*/}
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Pacote de Atualização</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleSubmit} className="mt-3">
            <Row>
              <div className="col">
                <label className="form-label">Tipo de Liberação</label>
                <SwitchCheckbox
                  name="ehHomologador"
                  id="ehHomologador"
                  label="Homologador?"
                  style={{ height: "33px" }}
                  onChange={(e: any) => handleFiltroHomologador(e.target.checked)}
                />
              </div>
              <p className="mt-2">Se o botão "homologador" estiver selecionado, os pacotes selecionados abaixo receberão essa marcação.</p>
            </Row>
            <Row className="mt-4">
              <label className="form-label col">Selecione os pacotes:</label>
              <div className="col-3">
                <ReactSelect
                  classNamePrefix="react-select"
                  name="referencia"
                  options={referencia}
                  isClearable={true}
                  placeholder="Busca por Referência..."
                  onChange={(e: any) => handleFiltroReferencia(e?.value)}
                />
              </div>
              <div className="col-auto">
                <GlobalFilter setGlobalFilter={setFilter} />
              </div>
            </Row>
            <Row className="mt-3">
              <div className={'dimmer' + (tableLoading ? ' active' : '')}>
                <div className="loader"></div>
                <div className={"dimmer-content" + (tableLoading ? ' small-box-loader' : '')} >

                  {tableData && (
                    <DataTableTSModal
                      setSelectedRows={setTableSelectedRows}
                      pageCount={pageCount}
                      filter={filter}
                      fetchData={fetchData}
                      filterOptional1={filterReferencia}
                      filterOptional2={filterHomologador}
                      columns={columnsModal}
                      data={tablePacoteData}
                      bordered={false}
                      initialState={{
                        sortBy: [
                          {
                            id: "id",
                            desc: false,
                          },
                        ],
                      }}
                    />
                  )}
                  <DataNotFound
                    visible={
                      tableLoading == false ? tablePacoteData?.length === 0 : false
                    }
                  ></DataNotFound>
                </div>
              </div>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={() => formRef.current?.submitForm()}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabAtualizacoes;
