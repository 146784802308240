export const simOuNao = [
    { label: "Sim", value: 1 },
    { label: "Não", value: 0 },
]

export const arredondamento = [
    { label: "P/CIMA", value: "P/CIMA" },
    { label: "SIM", value: "SIM" },
    { label: "NÃO", value: "NAO" },
]

export const status = [
    { label: "Ativo", value: 1 },
    { label: "Descontinuado", value: 2 },
    { label: "Arquivo Morto", value: 5 },
    { label: "Em homologação", value: 6 },
    { label: "Novo Portfólio", value: 7 },
]
export const unidade = [
    { label: "KG", value: 4 },
    { label: "MT", value: 3 },
]

export const situacao = [
    { label: "Em Edição", value: 1 },
    { label: "Em Homologação", value: 2 },
    { label: "Disponível para Mercado", value: 3 },
    { label: "Indisponível para Mercado", value: 4 },
]

export const tipoMaterial = [
    { label: "Componentes", value: "COMPONENTE" },
    { label: "Perfis", value: "PERFIL" },
    { label: "Vidros", value: "VIDRO" },
    { label: "Superficies", value: "SUPERFICIE" },
  ];
