import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { Button, Form } from "react-bootstrap";

import DataTable from "../../components/DataTable";
import DataNotFound from "../../components/DataNotFound";
import { debounce } from "lodash";
import api from "../../services/api";

import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";
import { ToastContext } from "../../contexts/toast/toastContext";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";

import { IListUsuario } from "../../common/interfaces/usuario.interfaces";

const Usuarios: React.FC = () => {

  //Fields Typed
  const DataTableTS: any = DataTable;

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);

  //States
  const [tableData, setTableData] = useState<IListUsuario[]>([]);
  const [tableLoading, setTableLoading] = useState(false);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);
        const { data } = await api.get("usuario/geralLista");

        setTableData(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, []);

  //Handles
  const handleChangeFilter = useCallback(
    debounce(async (e) => {
      const { data } = await api.get(`usuario/geralLista/${e.target.value}`);
      setTableData(data);

    }, 500),
    []
  );

  const handleDelete = ({ id, nome }: IListUsuario) => {
    const title = `Excluir "${nome}"?`;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`usuario/${id}`);
        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleSend = ({ id, nome, email }: IListUsuario) => {
    const title = `Enviar dados de "${nome}"?`;
    const message = `Os dados de acesso serão enviados ao e-mail cadastrado.`;
    sweetAlertContext.warning(title, message, async () => {
      try {
        await api.post(`usuario/enviarDadosAcesso/${id}`);
        console.log(id, nome, email)
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "nome",
        display: "align-middle",
      },
      {
        Header: "E-mail",
        accessor: "email",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({ row: { original } }: { row: { original: IListUsuario } }) => (
          <span className="d-none d-sm-inline">
            <Button title="Enviar dados de acesso" variant="ghost-dark" className="p-2" onClick={() => handleSend(original)}>
              <i className="far fa-share-square icon m-0"></i>
            </Button>
            <Button title="Excluir" variant="ghost-dark" className="p-2" onClick={() => handleDelete(original)}>
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <h2 className="col-auto page-title">Usuários Gerais</h2>
              <span className="ml-auto col-auto pr-0">
                <Form.Control
                  placeholder="Busca..."
                  onChange={handleChangeFilter}
                />
              </span>
            </div>
          </div>

          <div className="row">
            <div className="card p-0">
              <div className="card-body">
                <div className={'dimmer' + (tableLoading ? ' active' : '')}>
                  <div className="loader"></div>
                  <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
                    {tableData && (
                      <DataTableTS
                        columns={columns}
                        data={tableData}
                        bordered={false}
                        initialState={{
                          sortBy: [
                            {
                              id: "id",
                              desc: false,
                            },
                          ],
                        }}
                      />
                    )}

                    <DataNotFound
                      visible={
                        tableLoading == false ? tableData?.length === 0 : false
                      }
                    ></DataNotFound>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Usuarios;
