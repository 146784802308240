import React, {
    useCallback,
    useContext,
    useMemo,
    useState,
    useRef,
    useEffect
  } from "react";
  import { Modal, Button , Fade} from "react-bootstrap";
  import { Input, InputDecimal, Select } from "../../components/Form";
  import Breadcrumbs from "../../components/Breadcrumbs";
  import PaginationTable from "../../components/DataTable/paginationTable-BuscaExt";
  import DataNotFound from "../../components/DataNotFound";
  import api from "../../services/api";
  
  import { ToastContext } from "../../contexts/toast/toastContext";
  import { ToastErrorContext } from "../../contexts/toast/toastErrorContext";
  import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";
  import { useAuth } from "../../hooks/auth";
  import { Form } from "@unform/web";
  import { SubmitHandler, FormHandles } from "@unform/core";
  import getValidationErrors from "../../helpers/getValidateErrors";
  import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";
  import * as Yup from "yup";
  
  import { IFormAcabamento, IListAcabamento } from "../../common/interfaces/acabamento.interfaces";
  import { IPaginacao } from "../../common/interfaces/paginacao.interfaces";
  import GlobalFilter from "../../components/DataTable/GlobalFilter";
  import { toast } from "react-toastify";
  import {
    situacao,
    status,
    unidade,
  } from "../../components/Form/Select/options";
  
  import { ProdutoContext } from "../../contexts/produtos/produtosContext";
import { da } from "date-fns/locale";
  const Acabamento: React.FC<any> = ({
    loadingValues,
  }) => {
  
    // Refs
    const formRef = useRef<FormHandles>(null);
    const { user } = useAuth();
    const emEdicao = useRef(false);
    //Constates
    const queryString = require('querystring');
  
    //Fields Typed
    const DataTableTS: any = PaginationTable;
  
    //Context
    const sweetAlertContext = useContext(SweetAlertContext);
    const toastContext = useContext(ToastContext);
    const toastErrorContext = useContext(ToastErrorContext);
  
  
    //States
    const [tableData, setTableData] = useState<IListAcabamento[]>([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState<IFormAcabamento>({} as IFormAcabamento);
    const [novo, setNovo] = useState(false);
    const [reloadGrid, setReloadGrid] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [filter, setFilter] = useState('');
    const produtoContext = useContext(ProdutoContext);
    const [selectValues, setSelectValues] = useState<any>({});
    const [homologar, setHomologar] = useState(false);
    const [disponivel, setDisponivel] = useState(false);
    const [modoEdicao, setModoEdicao] = useState(false);
    //Callbacks
    
    //Handles
    const handleClose = () => setShow(false);
  
    const handleNew = () => {
      setSelected({} as IFormAcabamento);
      setNovo(true);
      setShow(true);
    };
  
    const handleEdit = useCallback(async ({ id }) => {
      const { data } = await api.get(`/tratamento/${id}`);
      setSelected(data);
      setShow(true);
      setNovo(false);
    }, []);


    const handleDelete = ({ id, descricao }: IListAcabamento) => {
      const title = `Excluir "${descricao}"?`;
      const message = "Esse Acabamento será excluído, isso não pode ser desfeito.";
      sweetAlertContext.danger(title, message, async () => {
        try {
          await api.delete(`tratamento/${id}`);
          setTableData((prevState) =>
            prevState.filter(function (item) {
              return item.id !== id;
            })
          );
          sweetAlertContext.close();
        } catch (error) {
          const message = getSingleErrorMessage(error);
          if (message) {
            toastContext.notification(message, "warn");
          } else {
            toastContext.notification(
              "Ocorreu um erro ao realizar essa operação.",
              "error"
            );
          }
        }
      });
    };
  
    const handleSave = () => {
      formRef.current?.submitForm();
      setNovo(false);
    };
    const handleAcaoPermitida = ({ homologar, disponibilizar }: any) => {
      setHomologar(homologar);
      setDisponivel(disponibilizar);
    };
    const handleChangeField = () => {
      if (!produtoContext.entrouEmEdicao && !loadingValues) {
       produtoContext.setEntrouEmEdicao(true);
     }
   };  
   const handleModoEdicao = async (modoEdicao: boolean) => {
    try {
      await api.post(`tratamento/modoEdicao/${modoEdicao}`);
      setModoEdicao(modoEdicao);
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
    }
  };
    const handleSubmit: SubmitHandler<IFormAcabamento> = async (tratamentoviewModel) => {
      try {
        formRef.current?.setErrors({});
  
        const message = "Campo obrigatório";
        const schema = Yup.object().shape({
          codigo: Yup.string().required(message),
          descricao: Yup.string().required(message),
        });
        await schema.validate(tratamentoviewModel, { abortEarly: false });
        const tratamentos: Omit<IFormAcabamento, 'unidade'> & { unidade: string } = {
          id: selected.id, 
          codigo: tratamentoviewModel.codigo,
          descricao: tratamentoviewModel.descricao,
          status: tratamentoviewModel.status,
          situacao: tratamentoviewModel.situacao,
          referencia: tratamentoviewModel.referencia,
          tipoAcabamento: tratamentoviewModel.tipoAcabamento,
          loteMinimo: tratamentoviewModel.loteMinimo,
          unidade: tratamentoviewModel.unidade.label,
         
        };
    
        await api.post('tratamento', tratamentos);
  
        setReloadGrid(reload => !reload);
        await fetchData({ pageIndex: 0, pageSize: 10, globalFilter: filter } as IPaginacao, modoEdicao);
        setShow(false);
        //Toast
        toast.success("A ação foi concluída.");
      } catch (error: any) {
        toast.error(getSingleErrorMessage(error));
        formRef.current?.setErrors(getValidationErrors(error));
      }
    };

    
    useEffect(() => {
      (async () => {
          try {
              const referencia = await api.get("geral/selecaoReferenciaMaterial/ACABAMENTO");
              const tipoAcabamento = await api.get("geral/SelecaoTodosAcabamentos");
              setSelectValues({
                  referencia: referencia.data,
                  tipoAcabamento: tipoAcabamento.data
              });
              const modoEdicao = await api.get("tratamento/modoEdicaoValue");
              setModoEdicao(modoEdicao.data);
                
              await api
                .get("tratamento/acaoPermitidaMaterial")
                .then((response) => handleAcaoPermitida(response.data))
                .catch((error) => toast.error(getSingleErrorMessage(error)));
              await fetchData({ pageIndex: 0, pageSize: 10, globalFilter: filter } as IPaginacao, modoEdicao);
          } catch (error) {
              console.error("Erro ao buscar referência:", error);
          }
      })();
    }, [modoEdicao, reloadGrid, filter]);

    const fetchData = useCallback(async (paginacao: IPaginacao, modoEdicao) => {
      const modoEdicaoResul = await api.get("tratamento/modoEdicaoValue");

      try {
        const { data: { dados, paginas } } = await api.get(`/tratamento/Lista?${queryString.stringify(paginacao)}&modoEdicao=${modoEdicaoResul.data}`);
        setPageCount(paginas);
        setTableData(dados);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    }, []);
  
    const columns = useMemo(
      () => [
        {
          Header: "Código",
          accessor: "codigo",
          display: "align-middle",
        },
        {
          Header: "Descrição",
          accessor: "descricao",
          display: "align-middle",
        },
        {
          Header: "Lote Mínimo",
          accessor: "loteMinimo",
          display: "align-middle",
        },
        {
          Header: "Unidade",
          accessor: "unidade",
          display: "align-middle",
        },
        {
          Header: "Situação",
          accessor: "situacao",
          display: "align-middle",
        },
        {
          Header: "Ação",
          accessor: "acao",
          disableSortBy: true,
          display: "align-middle column-table-2b",
          Cell: ({
            row: { original },
          }: {
            row: { original: IListAcabamento };
          }) => (
            <>
              {modoEdicao && (
                <>
                  <Button
                    title="Editar"
                    variant="ghost-dark"
                    className="p-2"
                    onClick={() => handleEdit(original)}
                  >
                    <i className="far fa-edit icon m-0"></i>
                  </Button>
                  <Button
                    title="Excluir"
                    variant="ghost-dark"
                    className="p-2"
                    onClick={() => handleDelete(original)}
                  >
                    <i className="far fa-trash-alt icon m-0"></i>
                  </Button>
                </>
              )}
            </>
          ),
        },
      ],
      [modoEdicao, handleEdit, handleDelete]
    );

    
  function havePermission(permission: string): boolean {
    var isValid = user?.role.indexOf(permission) !== -1;
    return isValid;
  }
    const handleHomologar = async () => {
      const title = "Homologar";
      const message =
        "Deseja homologar as alterações realizadas, isso não pode ser desfeito.";
  
      sweetAlertContext.warning(title, message, async () => {
        try {
          var promise = new Promise(async (resolve, reject) => {
            try {
              const {
                data: { total },
              } = await api.post("tratamento/homologar");
              resolve(response);
            } catch (error) {
              reject(error);
              toast.error(getSingleErrorMessage(error));
            }
          });
  
          var response = await toast
            .promise(promise, {
              pending: "Homologando acabamentos...",
              error: "Não há acabamentos em edição para homologação",
              success:
                "A ação foi concluída. Em breve será disponibilzado o pacote de atualização para importação no seu sistema para homologação.",
            })
            .then(async (data) => {
              const acaoPermitida = await api.get(
                "produto/acaoPermitidaMaterial"
              );
            });
            setReloadGrid(reload => !reload);
            await fetchData({ pageIndex: 0, pageSize: 10 , globalFilter: filter} as IPaginacao, modoEdicao);
        } catch (err) {
          toast.error(getSingleErrorMessage(err));
        }
      });
    };
  
    const handleDisponibilizar = async () => {
      const title = "Disponível para Mercado";
      const message =
        "Deseja tornar a atualizações disponíveis para uso dos clientes?";
      sweetAlertContext.warning(title, message, async () => {
        try {
          var promise = new Promise(async (resolve, reject) => {
            api
              .post("tratamento/disponibilizar")
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error.response.data);
              });
          });
  
          await toast
            .promise(promise, {
              pending: "Disponibilizando acabamentos...",
              error: {
                render({ data }: any) {
                  return data;
                },
              },
              success:
                "A ação foi concluída. Em breve estará disponível para importação nos sistemas dos clientes.",
            });

            setReloadGrid(reload => !reload);
            await fetchData({ pageIndex: 0, pageSize: 10, globalFilter : filter } as IPaginacao, modoEdicao);
        } catch (err) {
          toast.error(getSingleErrorMessage(err));
        }
      });
    };
  
    return (
      <>
        <div className="content">
          <div className="container-xl">
          <div className="page-header">
            <div className="row">
                <div className="col-3">
                  <h2 className="page-title">Acabamentos</h2>
                </div>
                <div className="col-auto">
                  <div className="btn-list mr-2">
                    <Fade in={modoEdicao}>
                      <div>
                        {modoEdicao && (
                          <>
                            <Button
                              variant="white"
                              className="mr-2"
                              onClick={handleNew}
                            >
                              Novo Acabamento
                            </Button>
                            <Button
                              variant="white"
                              className="mr-2"
                              onClick={handleHomologar}
                              disabled={!homologar}
                            >
                              Homologar
                            </Button>
                            {havePermission("Master") && (
                              <Button
                                variant="white"
                                onClick={handleDisponibilizar}
                                disabled={!disponivel}
                              >
                                Disponibilizar
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </Fade>
                  </div>
                </div>
                {emEdicao && (
                  <div className="mt-2 pr-0 col-auto ml-auto">
                    <label className="custom-switch" htmlFor="edicao">
                      <input
                        type="checkbox"
                        id="edicao"
                        checked={modoEdicao}
                        className="custom-switch-input"
                        onChange={(e) => handleModoEdicao(e.target.checked)}
                      />
                      <span className="custom-switch-indicator"></span>
                      <span className="custom-switch-description">
                        Modo Edição
                      </span>
                    </label>
                  </div>
                )}
                <div className="col-auto ml-0 pr-0">                
                    <GlobalFilter setGlobalFilter={setFilter} />                
                </div>
              </div>
            
          </div>
            <div className="row">
              <div className="card p-0">
                <div className="card-body">
                  <div className={'dimmer' + (tableLoading ? ' active' : '')}>
                    <div className="loader"></div>
                    <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
                      {tableData && (
                        <DataTableTS
                          columns={columns}
                          data={tableData}
                          filter={filter}
                          fetchData={fetchData}
                          pageCount={pageCount}
                          bordered={false}
                          reload={reloadGrid}
                          initialState={{
                            sortBy: [
                              {
                                id: "id",
                                desc: false,
                              },
                            ],
                          }}
                        />
                      )}
                      <DataNotFound
                        visible={
                          tableLoading == false ? tableData?.length === 0 : false
                        }
                      ></DataNotFound>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* Modal Acabamento */}
        <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {novo ? "Adicionar Acabamento" : "Editar Acabamento"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form initialData={selected} className="mt-3" ref={formRef} onSubmit={handleSubmit}>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label">Status *</label>
                <Select
                  name="status"
                  options={status}
                  placeholder="Selecione uma opção..."
                  defaultValue={selected.status ? { value: selected.status.value, label: selected.status.label } : null}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="form-label">Situação *</label>
                <Select
                  name="situacao"
                  options={situacao}
                  placeholder="Selecione uma opção..."
                  defaultValue={selected.situacao ? { value: selected.situacao.value, label: selected.situacao.label } : null}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label">Código</label>
                <Input
                  type="text"
                  name="codigo"
                  className="form-control"
                  placeholder="Código..."
                />
              </div>
              <div className="form-group col-md-6">
                <label className="form-label">Referência *</label>
                <Select
                  name="referencia"
                  options={selectValues.referencia}
                  placeholder="Selecione uma opção..."
                  value={selected.referencia}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12">
                <label className="form-label">Descrição</label>
                <Input
                  type="text"
                  name="descricao"
                  className="form-control"
                  placeholder="Descrição..."
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label">Lote Mínimo</label>
                <Input
                  type="number"
                  name="loteMinimo"
                  className="form-control"
                  placeholder="Lote Mínimo..."
                  defaultValue={novo? 0 : selected.loteMinimo}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="form-label">Tipo Acabamento</label>
                <Select
                  name="tipoAcabamento"
                  options={selectValues.tipoAcabamento}
                  placeholder="Selecione uma opção..."
                  value={selected.tipoAcabamento}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="form-label">Unidade</label>
                <Select
                  name="unidade"
                  options={unidade}
                  placeholder="Selecione uma opção..."
                  defaultValue={selected.unidade ? { value: selected.unidade, label: selected.unidade } : null}
                />
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
  
      </>
    );
  };
  
  export default Acabamento;