import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Row, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";

import { Input, Select, SwitchCheckbox } from "../../../../../components/Form";
import DataTable from "../../../../../components/DataTable";
import DataNotFound from "../../../../../components/DataNotFound";
import api from "../../../../../services/api";
import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";
import getValidationErrors from "../../../../../helpers/getValidateErrors";
import { IFormGeral, IListLiberacaoAlumisoft, IListProjetista } from "../../../../../common/interfaces/liberacoes.interfaces";
import { useAuth } from "../../../../../hooks/auth";

interface IGeral {
  dados: IListLiberacaoAlumisoft
}

const TabGeral: React.FC<IGeral> = (props) => {
  //Fields Typed
  const DataTableTS: any = DataTable;

  const { user } = useAuth();

  // Refs
  const formRef = useRef<FormHandles>(null);

  const formGeral = useRef<FormHandles>(null);
  const schemaFormGeral = Yup.object().shape({
    id: Yup.number().required("Campo obrigatório"),
    qtdeLimitePerfil: Yup.number().required("Campo obrigatório 1"),
    qtdeLimiteComponente: Yup.number().required("Campo obrigatório 2"),
    bloqueadaAtualizacao: Yup.boolean().required("Campo obrigatório 3"),
    bloqueadaLiberacao: Yup.boolean().required("Campo obrigatório 4"),
  });

  // Context
  const toastContext = useContext(ToastContext);
  const sweetAlertContext = useContext(SweetAlertContext);

  //States
  const [tableData, setTableData] = useState<IListProjetista[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [dataGeral, setDataGeral] = useState({} as IFormGeral);
  const [projetistas, setProjetistas] = useState([]);
  const [reloadTablePA, setReloadTablePA] = useState(false);

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      Promise.all([
        api.get(`serial/geral/${props.dados.serial}`),
        api.get('referencia/projetistaSelect')
      ]).then((responses) => {
        const geral = responses[0];
        const projetista = responses[1];
        setDataGeral(geral.data);
        setProjetistas(projetista.data);
      }).catch((err) => {
        toast.error(getSingleErrorMessage(err));
      }).finally(() => {
        setTableLoading(false);
      });
    }

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        const { data } = await api.get(`serial/listaProjetistaAberto/${props.dados.idSerial}`);
        setTableData(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    }

    fetchData();
  }, [reloadTablePA]);

  //Handles
  const handleSubmit: SubmitHandler = async (dados) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        projetista: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
      });

      await schema.validate(dados, { abortEarly: false });

      const projetistaAberto = { idSerial: props.dados.idSerial, idReferencia: dados.projetista.value };
      await api.post('serial/salvarProjetistaAberto', projetistaAberto);

      setReloadTablePA(reload => !reload);
      toast.success("A ação foi concluída.");
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleSaveLimiteCadastro: SubmitHandler = async (dados) => {
    var data = schemaFormGeral.cast(dados);
    if (await schemaFormGeral.isValid(data)) {

      await api.post('serial/salvarGeral', data)
        .then((response: any) => {
          if (response.data.isValid) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.errors[0]);
          }
        }).catch(error => {
          toast.error(getSingleErrorMessage(error));
        });

    } else {
      schemaFormGeral.validate(data)
        .catch((error) => {
          toast.error(error.message);
        })
    }

  }

  const handleDelete = ({ id, projetista }: IListProjetista) => {
    const title = `Excluir "${projetista}"? `;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`serial/RemoverProjetistaAberto/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Projetista",
        accessor: "projetista",
        display: "align-middle",
      },
    ],
    []
  );

  if (user?.role.indexOf("ConsultaLiberacao") === -1) {
    const deleteColumn = {
      Header: "Ação",
      accessor: "acao",
      disableSortBy: true,
      display: "align-middle column-table-1b",
      Cell: ({ row: { original } }: { row: { original: IListProjetista } }) => (

        <Button variant="ghost-dark" className="p-2" onClick={() => handleDelete(original)}>
          <i className="far fa-trash-alt icon m-0"></i>
        </Button>
      ),
    }
    columns.push(deleteColumn);
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row px-3 align-items-center">
            <div className="col">
              <h5 className="mb-2">Razão Social:</h5>
              <p className="mb-0">{props.dados.razaoSocial}</p>
            </div>
            <div className="col">
              <h5 className="mb-2">Nome Fantasia:</h5>
              <p className="mb-0">{props.dados.nomeFantasia}</p>
            </div>
            <div className="col-auto">
              <h5 className="mb-2">Serial:</h5>
              <p className="mb-0">{props.dados.serial}</p>
            </div>
          </div>
        </div>
      </div>

      <Form ref={formGeral} onSubmit={handleSaveLimiteCadastro}>
        <Row className="mt-4">
          <Input type="hidden" name="id" defaultValue={dataGeral.id} />

          <div className="col" style={{ borderRight: "1px solid rgba(101,109,119,.2)" }}>
            <Row>
              <div className="">
                <label className="form-label">Limite de Cadastro - Perfil</label>
                <Input
                  type="text"
                  name="qtdeLimitePerfil"
                  className="form-control col-5"
                  placeholder="Digite o limite..."
                  defaultValue={dataGeral.qtdeLimitePerfil}
                  maxLength={3}
                  onlyNumbers
                />
              </div>
            </Row>
            <Row className="mt-3">
              <div className="">
                <label className="form-label">Limite de Cadastro - Componentes</label>
                <Input
                  type="text"
                  name="qtdeLimiteComponente"
                  defaultValue={dataGeral.qtdeLimiteComponente}
                  className="form-control col-5"
                  placeholder="Digite o limite..."
                  maxLength={3}
                  onlyNumbers
                />
              </div>
            </Row>
          </div>
          
          <div className="col-6 pl-4">
            <Row className="">
              <div className="col">
                <label className="form-label">Bloquear Atualizações</label>
                <SwitchCheckbox
                  name="bloqueadaAtualizacao"
                  id="bloqueadaAtualizacao"
                  defaultChecked={dataGeral?.bloqueadaAtualizacao}
                  style={{ height: "33px" }}
                />
              </div>
            </Row>
            <Row className="mt-3">
              <div className="col">
                <label className="form-label">Bloquear Liberações em Massa</label>
                <SwitchCheckbox
                  name="bloqueadaLiberacao"
                  id="bloqueadaLiberacao"
                  defaultChecked={dataGeral?.bloqueadaLiberacao}
                  style={{ height: "33px" }}
                />
              </div>
            </Row>
          </div>

          <Row className="mt-2 pl-3">
            <Button variant="white" onClick={() => formGeral.current?.submitForm()}>
              Salvar Limite Cadastro
            </Button>
          </Row>
        </Row>
      </Form>

      <hr className="my-4" />
      <Row>
        <div className="form-label col">Projetistas Abertos para Edição</div>
      </Row>
      <Form ref={formRef} onSubmit={handleSubmit}>
        {user?.role.indexOf("ConsultaLiberacao") === -1 && (
          <Row className="mt-2">
            <div className="col">
              <Select
                name="projetista"
                options={projetistas}
                placeholder="Selecione uma opção..."
              />
            </div>

            <div className="col-auto ml-auto">
              <Button
                variant="white"
                onClick={() => formRef.current?.submitForm()}
              >
                Adicionar Projetista
              </Button>
            </div>

          </Row>
        )}
      </Form>
      <Row className="mt-3">
        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' small-box-loader' : '')} >

            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={
                tableLoading == false ? tableData?.length === 0 : false
              }
            ></DataNotFound>
          </div>
        </div>
      </Row>
    </>
  );
};

export default TabGeral;
