import React, {
    useContext,
    useEffect,
    useRef,
    useState,
  } from "react";
  import { Button } from "react-bootstrap";
  import { Select } from "../../../components/Form";
  import { exportToExcel } from "../../../helpers/ExportToExcel";
  
  import { ToastContext } from "../../../contexts/toast/toastContext";
  
  import { Form } from "@unform/web";
  import { SubmitHandler, FormHandles } from "@unform/core";
  import getValidationErrors from "../../../helpers/getValidateErrors";
  import * as Yup from "yup";
  
  import api from "../../../services/api";
  
  import { situacao, status } from "../../../components/Form/Select/options";
  import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
  import { toast } from "react-toastify";
  
  const TabAcabamentos: React.FC = () => {
  
    //Fields
    const queryString = require('querystring');
  
    // Refs
    const formRef = useRef<FormHandles>(null);
  
    //Context
    const toastContext = useContext(ToastContext);
    const sweetAlertContext = useContext(SweetAlertContext);
  
    //States  
    const [referencia, setReferencia] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
  
    //Effects
    useEffect(() => {
      (async () => {
        const { data } = await api.get("geral/ReferenciaTratamentoBase");
        setReferencia(data);
      })();
    }, []);
  
    //Handles
    const handleSave = () => {
      formRef.current?.submitForm();
    };
  
 
    const handleSubmit: SubmitHandler = async (data) => {
      try {
        formRef.current?.setErrors({});
  
        const message = "Campo obrigatório";
        const schema = Yup.object().shape({
          situacao: Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })
            .nullable()
            .required(message),
          status: Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })
            .nullable(),
          referencia: Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })
            .nullable(),
        });
        await schema.validate(data, { abortEarly: false });
        //Passou pela validação      
        setTableLoading(true);
  
        const fileName = "Acabamentos";
  
        const filtro = {
          referencia: data.referencia?.value,
          situacao: data.situacao?.value,
          status: data.status?.value
        };
  
        const dadosExportacao = await api.get(`Tratamento/exportar?${queryString.stringify(filtro)}`);
    
        const tabDados = [] as any;
        if ((dadosExportacao.data?.length !== undefined && dadosExportacao.data?.length !== 0)) {
            tabDados.push({ data: dadosExportacao.data, name: "acabamentos"});
            debugger
            exportToExcel(tabDados, fileName);
            toast.success("A ação foi concluída.");
        }
        else
          sweetAlertContext.alert("Atenção", "Não há dados a serem exportados de acordo com os filtros realizados.");
      } catch (err) {
        console.log(err);
        formRef.current?.setErrors(getValidationErrors(err));
      }
      finally {
        setTableLoading(false);
      }
    };
  
    return (
      <>
        <div className="mb-3 col pr-0 text-right">
          <span className="d-none d-sm-inline">
            <Button onClick={handleSave} variant="white">
              Exportar
            </Button>
          </span>
        </div>
  
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className={'dimmer' + (tableLoading ? ' active' : '')}>
            <div className="loader"></div>
            <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
  
              <div className="form-row">
                <div className="col form-group">
                  <label className="form-label">Situação</label>
                  <Select
                    name="situacao"
                    placeholder="Selecione uma opção..."
                    options={situacao}
                    isClearable
                  ></Select>
                </div>
              </div>
  
              <div className="form-row">
                <div className="col form-group">
                  <label className="form-label">Status</label>
                  <Select
                    name="status"
                    placeholder="Selecione uma opção..."
                    options={status}
                    isClearable
                  ></Select>
                </div>
              </div>
  
              <div className="form-row">
                <div className="col form-group">
                  <label className="form-label">Referência</label>
                  <Select
                    name="referencia"
                    placeholder="Selecione uma opção..."
                    options={referencia}
                    isClearable
                  ></Select>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </>
    );
  };
  
  export default TabAcabamentos;
  